function extendz(claz, superclaz) {
    function Inner() {}
    Inner.prototype = superclaz.prototype;
    claz.prototype = new Inner();
    claz.prototype.constructor = claz;
}

function linkedContent(root, containerSelector, linkSearch, condition) {
    root.on("click keydown", containerSelector, function(e) {
        if (condition && !condition()) {
            return;
        }

        // Obsługa {event} keydown 'Enter'
        if (e.keyCode !== 13 && e.type !== 'click') return;

        var a;
        var $target = $(e.target);
        if ($target.closest("A")[0]) {
            // kliknięcie w A więc nic nie robimy
            return;
        }

        var $container = $target.closest(containerSelector);
        if (typeof linkSearch == "string") {
            // przekazany string czyli selector szukania wewnątrz contenera
            a = $container.find(linkSearch);
        } else if (typeof linkSearch == "function") {
            // przekazana funkcja która jako argument otrzyma $target, nie container gdyż powstaja na zewnątrz, tam
            // gdzie containerSelector jest znany i przekazywany jako argument
            a = linkSearch.call(this, $target);
        } else {
            // szukanie w buttonach i luzem
            a = $container.find(".buttons A");
            if (!a[0]) {
                a = $container.find("A");
            }
        }
        if (!a[0]) {
            // nie ma linku
            return;
        }

        $container.addClass('highlightingCliksLink');
        setTimeout(function() {
            $container.removeClass('highlightingCliksLink');
            simulateClick(a[0]);
        }, 100);
    });

    function simulateClick(el) {
        try {
        var evt = document.createEvent("MouseEvents");
        evt.initMouseEvent("click", true, true, window, 1, 0, 0, 0, 0,
        false, false, false, false, 0, null);
        el.dispatchEvent(evt);
        } catch(err) {
            el.click();
        }
    }
}

function ckEditorPluginExpand($componentElement) {
    const expandableElement = $componentElement.find('.expand-button');

    expandableElement.each(function () {
        var buttonContainer = $(this),
            button = buttonContainer.find('a'),
            contentArea = button.closest('.content_area'),
            collapseLabel = button.attr('data-collapse'),
            expandLabel = button.attr('data-expand'),
            $collapsibleContent;

        buttonContainer.nextAll().wrap('<div class="collapsible-content" />');
        $collapsibleContent = contentArea.find('.collapsible-content');
        buttonContainer.detach().appendTo(contentArea);

        if(button.hasClass("plugin-only-modal")) {
            button.closest(".content_area").addClass("plugin-only-modal");
        }

        button.onPage("click", function(event) {
            event.preventDefault();
            var _self = $(this);
            _self.toggleClass('link--active');
            if($collapsibleContent.hasClass("visible")){
                $collapsibleContent
                    .slideUp()
                    .removeClass('visible');
                button.text(expandLabel);
            } else {
                $collapsibleContent
                    .slideDown()
                    .addClass('visible');
                button.text(collapseLabel);
            }
        });
    });
}

function ckEditorPluginLikToFile() {
    $("#outlet-content").on('content-replace', function() {
        $('.link_to_file').each(function () {
            var containerFile = $(this).find('a'),
                labelFile = containerFile.find('.file_download');
            containerFile.addClass("link_to_file__href")
            if(!labelFile.exists()) {
                containerFile.addClass("no-label-file");
            }
        });
    });
}

function ckEditorPluginChangelog() {
    const plugin_changelog = $('.plugin_changelog'),
          plugin_changelog_parent = plugin_changelog.parent();

        plugin_changelog_parent.find(".plugin_changelog:first-child").addClass("first-element");
        plugin_changelog_parent.find(".plugin_changelog:last-of-type").addClass("last-element");

};

runFunction(function () {
    const bodyNode = document.querySelector('body');
    if (ComponentsManager.isPreview()) {
        bodyNode.classList.add('isPreview');
    }
    if (ComponentsManager.isBuilder()) {
        bodyNode.classList.add('isBuilder');
    }


    /*JS do obsługi pluginu ckEditor :: Dodanie box z linkiem do zasobu*/
    ckEditorPluginLikToFile()
    ckEditorPluginChangelog();

    $(document).onPage('mousedown', '[data-ripple]', function(evt) {
        var $self = $(this),
            initPos = $self.css("position"),
            offs = $self.offset(),
            diameter = Math.min(this.offsetHeight, this.offsetWidth, 100),
            axis = {
                x: evt.pageX - offs.left,
                y: evt.pageY - offs.top
            },
            $ripple = $('<div/>', {
                class: "ripple-effect",
                appendTo: $self
            });
    
        if (!initPos || initPos === "static") {
            $self.css({position: "relative"});
        }
    
        $('<div/>', {
            class: "ripple-effect__wave",
            css: {
                width: diameter,
                height: diameter,
                left: axis.x - (diameter / 2),
                top: axis.y - (diameter / 2)
            },
            appendTo: $ripple,
            one: {
                animationend: function() {
                    $ripple.remove();
                }
            }
        });
    });
});
